@import "../../../Common.css";

.payloTraditional{ //used only in graphs
  width: 100%;
  height: 95%;
}

@media (orientation: landscape){

  .dashboard{ //used in each of my scss
    //(size of screen minus the menu. puts everthing below the menu)
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: calc(100vh - 50px);
  }

  .financial{
    width: 100%;
  }

  .analytics{
    padding: 1.25rem;
    padding-bottom: 0rem;
    height: calc(100vh - 190px);
  }

  .flex{
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    align-items: space-between;
    height: calc(100vh - 190px - 1.25rem);
    width: calc(100% - 1.25rem);
  }

  .bargraph{
    width: 80%;
    height: 100%;
  }

  .tabs .nav-link {
      background-color: white;
      color: rgba(88, 214, 141, 1);
  }

  .tabs .nav-link:hover {
      background-color: rgba(88, 214, 141, .5);
  }

  .tabs .nav-link.active {
    background-color: rgba(88, 214, 141, 1);
    color: white;
  }

  .graphs{
    //height: calc(95vh - 120px);
    margin-top: 5;
    width: 100%;
  }

  .bars { //used only in finance
    width: 100%;
    height: 100%;
  }

  .doughnutgraph {
    display: flex;
    flex-wrap: wrap;
    width: 20%;
    height: 100%;
    margin: auto;
    flex-grow: 1;
  }

  .average {
    width: 100%;
    margin: auto;
    text-align: center;
    font-size: 18;
  }

  .fourdozen {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    align-content: space-around;
    align-items: space-around;
    margin: auto;
    flex-grow: 1;
  }

  .nut {
    max-height: 25%;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
  }
}

@media (orientation: portrait){

  .dashboard{ //used in each of my scss
    //(size of screen minus the menu. puts everthing below the menu)
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: calc(100vh - 50px);
  }

  .financial{
    width: 100%;
  }

  .analytics{
    padding: 1.25rem;
    padding-bottom: 0rem;
    height: calc(100vh - 190px);
  }

  .flex{
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    align-items: space-between;
    height: calc(100vh - 190px - 1.25rem);
    width: calc(100% - 1.25rem);
  }

  .bargraph{
    width: 100%;
    height: 90%;
    max-height: 100%;
  }

  .tabs .nav-link {
      background-color: white;
      color: rgba(88, 214, 141, 1);
  }

  .tabs .nav-link:hover {
      background-color: rgba(88, 214, 141, .5);
  }

  .tabs .nav-link.active {
    background-color: rgba(88, 214, 141, 1);
    color: white;
  }

  .graphs{
    //height: calc(95vh - 120px);
    margin-top: 5;
    width: 100%;
  }

  .bars { //used only in finance
    width: 100%;
  }

  .doughnutgraph{
    display: flex;
    flex-direction: column;
    align-items: space-between;
    width: 100%;
    margin: auto;
    height: 15%;
    align-self: flex-end;
  }

  .average {
    width: 100%;
    font-size: 1em;
    margin-bottom: 0px;
    text-align: center;
    display: block;
  }

  .fourdozen {
    display: flex;
    flex-direction: row;
    height: calc(100% - 1em);
  }

  .nut {
    width: 25%;
    flex-grow: 1;
    flex-shrink: 1;
  }
}

@media (max-width: 650px) and (orientation: portrait){

  .dashboard{ //used in each of my scss
    //(size of screen minus the menu. puts everthing below the menu)
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: calc(100vh - 50px);
  }

  .financial{
    width: 100%;
  }

  .analytics{
    padding: 1.25rem;
    padding-bottom: 0rem;
    height: calc(100vh - 190px);
  }

  .flex{
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    align-items: space-between;
    height: calc(100vh - 190px - 1.25rem);
    width: calc(100% - 1.25rem);
  }

  div.analytics.card-body{
    padding-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .bargraph{
    width: 100%;
    height: 65%;
    max-height: 100%;
  }

  .tabs .nav-link {
      background-color: white;
      color: rgba(88, 214, 141, 1);
  }

  .tabs .nav-link:hover {
      background-color: rgba(88, 214, 141, .5);
  }

  .tabs .nav-link.active {
    background-color: rgba(88, 214, 141, 1);
    color: white;
  }

  .graphs{
    //height: calc(95vh - 120px);
    margin-top: 5;
    width: 100%;
  }

  .bars { //used only in finance
    width: 100%;
  }

  .doughnutgraph{
    display: flex;
    flex-direction: column;
    align-items: space-between;
    width: 100%;
    margin: auto;
    height: 40%;
    align-self: flex-end;
  }

  .average {
    width: 100%;
    font-size: 1em;
    margin-bottom: 0px;
    text-align: center;
    display: block;
  }

  .fourdozen {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: calc(100% - 1em);
  }

  .nut {
    width: 50%;
    max-height: 50%;
    flex-grow: 1;
    flex-shrink: 1;
  }
}

@media (max-width: 650px) and (min-width: 500px) and (orientation: landscape){

  .dashboard{ //used in each of my scss
    //(size of screen minus the menu. puts everthing below the menu)
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: calc(100vh - 50px);
  }

  .financial{
    width: 100%;
  }

  .analytics{
    padding: 1.25rem;
    padding-bottom: 0rem;
    height: calc(100vh - 190px);
  }

  .flex{
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    align-items: space-between;
    height: calc(100vh - 190px - 1.25rem);
    width: calc(100% - 1.25rem);
  }

  .bargraph{
    width: 60%;
    height: 100%;
  }

  .tabs .nav-link {
      background-color: white;
      color: rgba(88, 214, 141, 1);
  }

  .tabs .nav-link:hover {
      background-color: rgba(88, 214, 141, .5);
  }

  .tabs .nav-link.active {
    background-color: rgba(88, 214, 141, 1);
    color: white;
  }

  .graphs{
    //height: calc(95vh - 120px);
    margin-top: 5;
    width: 100%;
  }

  .bars { //used only in finance
    width: 100%;
    height: 100%;
  }

  .doughnutgraph {
    display: flex;
    flex-wrap: wrap;
    width: 40%;
    height: 100%;
    margin: auto;
    flex-grow: 1;
  }

  .average {
    width: 100%;
    margin: auto;
    text-align: center;
    font-size: 18;
  }

  .fourdozen {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    align-content: space-around;
    align-items: space-around;
    margin: auto;
    flex-grow: 1;
  }

  .nut {
    width: 50%;
    height: 50%;
    flex-grow: 1;
    flex-shrink: 1;
  }
}

@media (max-width: 500px) and (orientation: landscape){

  .dashboard{ //used in each of my scss
    //(size of screen minus the menu. puts everthing below the menu)
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: calc(100vh - 50px);
  }

  .financial{
    width: 100%;
    height: 500%;
  }

  .analytics{
    padding: 1.25rem;
    padding-bottom: 0rem;
    height: calc(100vh - 190px);
  }

  .flex{
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    align-items: space-between;
    height: calc(100% - 190px - 1.25rem);
    width: calc(100% - 1.25rem);
  }

  .bargraph{
    width: 100%;
    height: 100vh;
  }

  .tabs .nav-link {
      background-color: white;
      color: rgba(88, 214, 141, 1);
  }

  .tabs .nav-link:hover {
      background-color: rgba(88, 214, 141, .5);
  }

  .tabs .nav-link.active {
    background-color: rgba(88, 214, 141, 1);
    color: white;
  }

  .graphs{
    //height: calc(95vh - 120px);
    margin-top: 5;
    width: 100%;
  }

  .bars { //used only in finance
    width: 100%;
    height: 100%;
  }

  .doughnutgraph {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 80%;
    margin: auto;
    flex-grow: 1;
  }

  .average {
    width: 100%;
    margin: auto;
    text-align: center;
    font-size: 18;
  }

  .fourdozen {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    align-content: space-around;
    align-items: space-around;
    margin: auto;
    flex-grow: 1;
  }

  .nut {
    width: 100%;
    height: 25%;
    flex-grow: 1;
    flex-shrink: 1;
  }
}
