@import "../../../../Common.css";

:root{
    --top-nav-height: 50px;
}

.execContainer{
    height: 100vh;
    display: grid;
    grid-template-columns: 250px 7fr;
    grid-template-rows: var(--top-nav-height) 5fr;
    grid-template-areas: 
        "header right-box"
        "left-box right-box"
}

.leftBox{
    grid-area: left-box;
    width: 250px;
    height: calc(100vh - var(--top-nav-height) * 2);
    overflow-x: hidden;
    overflow-y: scroll;
    position: fixed;
    top: calc(var(--top-nav-height) * 2);
    z-index: 99;
}

.rightBox{
    grid-area: right-box;
    height: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: [col-0] 5fr [col-1] 4fr [col-2];
    grid-template-rows: [row-0] 40px [row-1] 50px [row-2] 190px [row-3] 220px [row-4] 220px [row-5] 1fr [row-6];
}

.merchCards{
    grid-column-start: col-0;
    grid-column-end: col-2;
    grid-row-start: row-2;
    grid-row-end: row-3;
    padding: 10px;
    background-color: #fff;
}

.merchLine{
    grid-column-start: col-0;
    grid-column-end: col-2;
    grid-row-start: row-3;
    grid-row-end: row-5;
    padding: 10px;
    background-color: #fff;
}

.merchFilter{
    grid-column-start: col-0;
    grid-column-end: col-2;
    grid-row-start: row-1;
    grid-row-end: row-2;
    padding: 20px;
    background-color: #fff;
}

.isoTxn{
    grid-column-start: col-0;
    grid-column-end: col-2;
    grid-row-start: row-2;
    grid-row-end: row-3;
    background-color: #fff;
    padding: 10px;
}

.isoLine{
    grid-column-start: col-0;
    grid-column-end: col-1;
    grid-row-start: row-3;
    grid-row-end: row-5;
    background-color: #fff;
    padding: 10px;

}

.isoPie{
    grid-column-start: col-1;
    grid-column-end: col-2;
    grid-row-start: row-3;
    grid-row-end: row-4;
    background-color: #fff;
    padding-top: 0px;
}

.isoDeals{
    background-color: #fff;
    grid-column-start: col-1;
    grid-column-end: col-2;
    grid-row-start: row-4;
    grid-row-end: row-6;
    padding: 10px;
}

.isoTitle{
    grid-column-start: col-0;
    grid-column-end: col-2;
    grid-row-start: row-0;
    grid-row-end: row-1;
}

.isoTabs{
    grid-column-start: col-0;
    grid-column-end: col-2;
    grid-row-start: row-0;
    grid-row-end: row-1;
    z-index: 9;
}

.isoMCC{
    background-color: #fff;
    grid-column-start: col-0;
    grid-column-end: col-1;
    grid-row-start: row-5;
    grid-row-end: row-6;
    padding: 10px;
}

.isoMenuListItem{
    margin-top: 1px;
    height: 50px;
}

.isoMenuItem{
    font-size: 12px;
}

.isoMenuSearch{
    position: fixed;
    top: 60px;
    left: 20px;
    z-index: 99;
    background-color: #fff;
    width: 210px;
}

.isoLoading{
    grid-column-start: col-0;
    grid-column-end: col-1;
    grid-row-start: row-3;
    grid-row-end: row-5;
    background-color: rgba($color: #fff, $alpha: 0.7);
    z-index: 20;
    position: relative;
}

.merchLoading{
    grid-column-start: col-0;
    grid-column-end: col-2;
    grid-row-start: row-3;
    grid-row-end: row-5;
    background-color: rgba($color: #fff, $alpha: 0.7);
    z-index: 20;
    position: relative;
}

.isoSpinner{
    position: absolute;
    top: 50%;
    left: 50%;
}

.bg-txn{
    background-color: hsl(170, 51%, 59%);
    color: hsl(171, 49%, 18%);
    font-size: 15px;
    font-weight: 500;
}

.bg-ref{
    background-color: hsl(53, 84%, 65%);
    color: hsl(53, 71%, 24%);
    font-size: 15px;
    font-weight: 500;
}

.bg-chbk{
    background-color: hsl(8, 87%, 66%);
    color: hsl(8, 59%, 32%);
    font-size: 15px;
    font-weight: 500;
}

.bg-tick{
    background-color: #e8c1a0;
    color: #754925;
    font-size: 15px;
    font-weight: 500;
}

.bg-income{
    background-color: #72b3ec;
    color: #143770;
    font-size: 15px;
    font-weight: 500;
}

.accordianToggle{
    width: 100%;
}

.dropdown-menu{
    max-height:300px;
    overflow-y:auto;
}

.alert-esquire{
    background-color:#ffc107;
    color:#000;
}

.alert-cbcal{
    background-color:#28a745;
    color:#fff;
}

.alert-chesapeake{
    background-color:#bf8df2;
    color:#000;
}
