@import "../../../Common.css";

.buttonXlsx {
    color: #00a0dd;
    border-color: #00a0dd;
  }
  
  .buttonXlsx:hover {
    background-color: #00a0dd;
    border-color: #00a0dd;
    color: white;
  }