@import "../../../Common.css";

.leaderboardHeader {
  padding-top: 22px;
  margin: auto;
}

.leaderboardDeck {
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
}
