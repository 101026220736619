.menu{
  width: 100%;
  height: 50px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  background-image: linear-gradient(
    to right,
    rgba(0, 160, 221, 1),
    rgba(91, 190, 230, 1)
  );
}