@import "../../../Common.css";

.contentHeader {
  text-align: center;
  position: relative;
  // z-index: 1;
}

.leaderboardHeader {
  padding-top: 22px;
  margin: auto;
}

.partnerDeck {
  padding-left: 5%;
  padding-right: 5%;
}

.groupTable {
  height: 180px;
  -webkit-overflow-scrolling: touch;
}

.paginationHeader {
  text-align: center;
}

.center-fixed {
  text-align: center;
}
