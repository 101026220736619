@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500");
@import "../node_modules/bootstrap/scss/mixins/_hover.scss";

body {
  font-family: "Roboto", sans-serif;
  background: #fafafa;
}

h1 {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 1em;
  margin: 0;
  vertical-align: middle;
}

h2 {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 1em;
  margin: 0;
}

h3 {
  font-family: "Roboto";
  font-weight: 400;
  letter-spacing: 10px;
}

h6 {
  font-family: "Roboto";
  font-weight: 500;
  letter-spacing: 3px;
  font-size: 16pt;
}

.leaderboardHeader {
  color: rgba(0, 160, 221, 1);
}

.navButton {
  color: rgba(0, 160, 221, 1);
}

.navMonth {
  color: rgba(0, 160, 221, 1);
}
