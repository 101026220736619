@import "../../../Common.css";

//below here is unique
.dashboard{ //used in each of my scss
  //(size of screen minus the menu. puts everthing below the menu)
  width: 100vw;
  height: calc(100vh - 50px);
}

.Exports{ //used only in exports
  border: none;
  width: 100%;
  height: auto;
}

.exportbtn{ //used only in exports
  margin-top: 1rem;
}
