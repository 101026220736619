@import "../../../Common.css";

.card-body {
  font-family: "Roboto";
  font-weight: 400;
  height: auto;
}

.graph {
  padding-top: 10px;
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.card-row {
  padding-left: 25px;
  padding-top: 5px;
}

.customTable {
  overflow-y: auto;
  height: 265px;
}

.table th {
  font-weight: 400;
  border-bottom-width: 1px !important;
}

.table thead th {
  border-top: none !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.table tbody td,
.table tbody th {
  padding: 0.25rem;
  border: none;
}

.contentHeader {
  text-align: center;
  position: relative;
  // z-index: 1;
}

.navButton {
  background-color: Transparent;
  padding: 30px 12px 6px;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

button:focus {
  outline: 0;
  box-shadow: none !important;
}

.navButton:hover {
  background-color: Transparent;
  color: lightgray;
}

.navButton:focus .navButton:active {
  outline: none;
  border: 0;
  box-shadow: none !important;
}

.navMonth {
  padding-top: 22px;
  margin: auto;
}

.material-icons {
  line-height: 0.5;
}

.content {
  padding: 20px;
}

.popupContent {
  padding: 20px;
  text-align: center;
  -webkit-overflow-scrolling: touch;
}

.gray-icon {
  color: lightgray;
  background-color: transparent;
  float: right;
  cursor: pointer;
}

.bankByProfile{
  font-size: 14px;
  padding-right: 30px;
}
.bankByProfile> .row{
  border-bottom: 1px solid #ccc;
  padding: 3px 0;
}

.todayYestCardTitle{
  font-size: 1.25rem;
  font-weight: 500;
}

.todayYestCards{
  display: flex;
  flex-flow: column;
}

.month-select{
  border: 0;
  background-color: transparent;
  outline: 0;
  text-align: center;
  position: relative;
}

input::-webkit-datetime-edit {
  min-width: 0px;
  display: inline-block;
  -webkit-user-modify: read-only !important;
  flex: 1 1 0%;
  overflow: hidden;
}

.month-select::-webkit-calendar-picker-indicator {
  background: none;
  cursor: pointer;
  position: absolute;
  width: 100%;
}

.month-select::-webkit-datetime-edit-month-field, .month-select::-webkit-datetime-edit-year-field {
  background: none;
  -webkit-text-fill-color: #02a1dd;
}


.month-select::-webkit-datetime-edit-month-field, .month-select::-webkit-datetime-edit-year-field {
  background: none;
  -webkit-text-fill-color: #02a1dd;
}

.sales-card-title{
}