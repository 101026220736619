// @import "../../../Common.css";
.mph-container {
    width: 100%;
    height: calc(100vh - var(--top-nav-height));
    position: relative;
}

.mph-main-table-container {
    width: 100vw;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
}

.mph-main-table {
    width: 100vw;
    max-width: 1980px;
}

.mph-enhanced-table {
    width: 100%;
}

.mph-enhanced-table-container {
    width: 100%;
    height: calc(100vh - var(--top-nav-height) - 120px);
    overflow-x: hidden;
    overflow-y: scroll;
    border: #e9e9e9 1px solid;
  }

.mph-enhanced-table-footer-container{
    width: 100vw;
    max-width: 1980px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    border: #e9e9e9 1px solid;
  }

.mph-enhanced-table-footer{
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    width: 100vw;
    z-index: 5;
    display: flex;
    place-content: center;
  }

.mph-enhanced-table-pagination {
    justify-self: end;
}

.mph-enhanced-table-export {

}

.mph-enhanced-table-header {
    position: sticky;
    left: 0;
    top: 0;
    background-color: white;
    z-index: 10;
}

.mph-enhanced-table-header-cell {
    white-space: nowrap;
    margin-bottom: 10px;
}

.spinner-overlay{
    position: absolute;
    width: 100%;
    height: calc(100vh - var(--top-nav-height) - 70px);
    background-color:hsla(0, 0%, 0%, 0.4);
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.spinner-progress {
    text-align: center;
    width: 50%;
}

.mph-modal-container{
    height: auto;
}

.mph-table-header-center{
    text-align: center;
}

.mph-enhanced-table-header-row th:not(:last-child){
    border-right: #e9e9e9 1px solid;
}

.mph-enhanced-table-pagination td:last-child {
    border-bottom: 0;
} 

.mph-enhanced-table-body-row{
    background-color: white;
}

.mph-groups-table tr:nth-child(even){
    background-color: #f6f6f6;
}

.mph-groups-table th{
    background-color: #e9e9e9;
}

.mph-group-card{
    display: flex;
    flex-direction: column;
    position: relative;
}
.mph-group-card span:nth-child(n){
    margin: 1px 0;
}

.mph-group-add-button{
    position: absolute;
    right: 0;
    bottom: 5;
    display: flex;
    column-gap: 5px;
}

.mph-add-group-id .form-row{
    margin: 5px 0;
}

.mph-modal-error{
    position: absolute;
    left: 20;
    bottom: 5;
}

.mph-import{
    display: flex;
    align-items: center;
    height: 70px;
    padding-left: 20px;
    column-gap: 10px;
}

.mph-revshare{
    width: 150px;
    display: flex;
    align-items: center;
}

.mph-revshare input:focus {
    background: white;
}

.mph-revshare input {
    border: 0;
    background: transparent;
    width: 50px;
}