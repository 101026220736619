@import "../../../Common.css";

.scdashboard{
  margin-top: 50px;
  position: absolute;
  overflow-y: scroll;
  width: 100vw;
  height: calc(100vh - 50px);
}

.calculator{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.Merchant{
  width: 100%;
  position: sticky;
  top: 0;
  background-color: rgba(250, 250, 250, 1);
  z-index: 10
}

.SalesCalculator{
  padding: 1.25rem;
  padding-bottom: 0rem;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
}

.scflex{
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: stretch;
  width: 100%;
}

.monthly{
  width: 50%;
  margin: auto;
}

.yearly{
  width: 50%;
  margin: auto;
}

.total{
  margin: auto;
  text-align: center;
}

.totalnum{
  text-align: center;
  color: rgba(88, 214, 141, 1);
  font-size: xxx-large;
}

.merchantDetails{
  width: 100%;
}

.merchantDetailsToggle{
  text-align: center;
  background-color: rgba(88, 214, 141, .4);
}

.flexDetails{
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  align-items: space-between;
}

.detailInput{
  margin: auto;
  width: 33.333%;
}

.detailInput input{
  padding-left: 1rem;
  padding-right: 1rem;
  width:100%;
  border-top: white;
  border-left: white;
  border-right: white;
  color: rgba(88, 214, 141, 1);
}

.general{
  min-width: 33vh;
  max-width: 100%;
  width: 50%;
  max-height: 100%;
  min-height: 50%;
  flex-shrink: 1;
  flex-grow: 1;
}

.genBodyCard{
  max-height: 100%;
  min-height: 50%;
  border: grey;
  border-radius: 1;
}

.genBodyCard label{
  margin-bottom: 0;
  width:100%;
}

.genBodyCard input{
  padding-left: 1rem;
  padding-right: 1rem;
  width:100%;
  border-top: white;
  border-left: white;
  border-right: white;
  color: rgba(88, 214, 141, 1);
}

.genBodyCard input:disabled{
  background-color: white;
  color: rgba(200, 200, 200, 1);
}

.Specific{
  min-width: 40vh;
  max-width: 100%;
  width: 50%;
  max-height: 100%;
  min-height: 50%;
  flex-shrink: 1;
  flex-grow: 1;
}

.spectabs{
  margin-left: 0
}

.card-header-pills {
  margin: 0;
}

.sctabs .nav-link {
    background-color: white;
    color: rgba(88, 214, 141, 1);
}

.sctabs .nav-link.disabled{
  background-color: rgba(230, 230, 230, 1);
  color: rgba(141, 180, 141, 1);
  border-color: rgba(141, 180, 141, 1);
}

.sctabs .nav-link:hover {
    background-color: rgba(88, 214, 141, .5);
}

.sctabs .nav-link.active, {
  background-color: rgba(88, 214, 141, 1);
  color: white;
}

.specBodyCard{
  max-height: 100%;
  min-height: 50%;
  border-left: solid;
  border-left-color: lightgrey;
  border-radius: 10;
}

.specBodyCard label{
  margin-bottom: 0;
  padding-left: 1rem;
  width:100%;
}

.specBodyCard input{
  padding-left: 1rem;
  padding-right: 1rem;
  width:100%;
  border-top: white;
  border-left: white;
  border-right: white;
  color: rgba(88, 214, 141, 1);
}

.specBodyCard input:disabled{
  background-color: white;
  color: rgba(200, 200, 200, 1);
}

.radioSelect{
  margin-top: 1rem;
  width: 100%
}

.radioSelect .btn-outline-success{
  color: rgba(88, 214, 141, 1);
  border-color: rgba(88, 214, 141, 1);
}

.radioSelect .btn-outline-success:disabled, .radioSelect .btn-outline-success.disabled{
  background-color: rgba(230, 230, 230, 1);
  color: rgba(141, 180, 141, 1);
  border-color: rgba(141, 180, 141, 1);
}

.radioSelect .btn-outline-success:not(:disabled):not(.disabled).hover, .form-check .btn-outline-success:not(:disabled):not(.disabled):hover, .form-check .btn-outline-success:not(:disabled):not(.disabled).focus, .form-check .btn-outline-success:not(:disabled):not(.disabled):focus{
  background-color: rgba(88, 214, 141, .4);
}

.radioSelect .btn-outline-success:not(:disabled):not(.disabled).active{
  background-color: rgba(88, 214, 141, 1);
  border-color: rgba(88, 214, 141, 1);
}

.fields{
  margin-top: 1rem;
}
