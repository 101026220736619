@import "../../../Common.css";

.baseHeader{
  overflow-x: hidden;
}

.contentHeader {
  text-align: center;
  position: relative;
  // z-index: 1;
}

.leaderboardHeader {
  padding-top: 22px;
  margin: auto;
}

.importExport {
  text-align: center;
}

.partnerDeck {
  padding-left: 5%;
  padding-right: 5%;
}

.groupTable {
  height: 180px;
  -webkit-overflow-scrolling: touch;
}

.paginationHeader {
  text-align: center;
}

.selfserveIcon {
  color: rgba(0, 160, 221, 1);
}

.deleteIcon {
  color: rgba(211, 47, 47, 1);
}
